<template>
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49957 17.75H17.9996V19.25H7.49957V17.75ZM2.37707 18.5L0.44207 20.435L1.49957 21.5L4.49957 18.5L1.49957 15.5L0.43457 16.5575L2.37707 18.5ZM7.49957 10.25H17.9996V11.75H7.49957V10.25ZM2.37707 11L0.44207 12.935L1.49957 14L4.49957 11L1.49957 8L0.43457 9.0575L2.37707 11ZM7.49957 2.75H17.9996V4.25H7.49957V2.75ZM2.37707 3.5L0.44207 5.435L1.49957 6.5L4.49957 3.5L1.49957 0.5L0.43457 1.5575L2.37707 3.5Z"
      fill="currentColor"
    />
  </svg>
</template>
